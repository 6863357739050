<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Date From</label>
            <b-input
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Date To</label>
            <b-input
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onViewDetails(row.item)"
            >
              View Details
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-view-details"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="View Details"
    >
      <form
        role="form"
        novalidate
      >
        <b-form-group>
          <label for="user">Requestor Name</label>
          <b-input
            name="user"
            :value="booking.user"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="email">Email</label>
          <b-input
            name="email"
            :value="booking.email"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="venue">Venue</label>
          <b-input
            name="venue"
            :value="booking.venue"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="date_from">Date From</label>
          <b-input
            name="date_from"
            :value="booking.date_from"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="date_to">Date To</label>
          <b-input
            name="date_to"
            :value="booking.date_to"
            type="text"
            :disabled="true"
          />
        </b-form-group>

        <b-form-group>
          <label for="purpose">Purpose</label>
          <b-textarea
            name="purpose"
            :value="booking.purpose"
            rows="6"
            max-rows="6"
            :disabled="true"
          />
        </b-form-group>

      </form>
      Booked Item(s)
      <b-table
        hover
        responsive
        class="mt-2"
        :items="booking.details"
        :fields="tableTwoSettings.fields"
        show-empty
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AdminReportService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminBookingHistories',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Booking Histories'
    }
  },

  mixins: [formatter],

  data () {
    return {
      booking: {
        id: 0,
        user: '',
        email: '',
        venue: '',
        date_from: '',
        date_to: '',
        purpose: '',
        details: []
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'booked at', formatter: this.dateTimeFormatter, sortKey: 'bookings.created_at', sortable: true },
          { key: 'status' },
          { key: 'user' },
          { key: 'email' },
          { key: 'company_name' },
          { key: 'organization_name' },
          { key: 'venue' },
          { key: 'date_from' },
          { key: 'date_to' }
        ]
      },
      tableTwoSettings: {
        fields: [
          'index',
          { key: 'item_name' },
          { key: 'date_from', formatter: this.dateTimeFormatter },
          { key: 'date_to', formatter: this.dateTimeFormatter }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminReportService.getBookingHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onViewDetails (item) {
      this.booking.id = item.id
      this.booking.user = item.user
      this.booking.email = item.email
      this.booking.venue = item.venue
      this.booking.date_from = item.date_from
      this.booking.date_to = item.date_to
      this.booking.purpose = item.purpose
      this.booking.details = item.booking_details
      this.$bvModal.show('modal-view-details')
    },

    onFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>
